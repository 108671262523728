<template>
  <e-auto-complete class="auto-input"
                   v-model="text"
                   :values="dynamicItems"
                   :placeholder="placeholder"
                   @update="dynamicSearchPlace"
                   @input="inputEvt"
  >
  </e-auto-complete>
</template>

<script>
import apiVslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import EAutoComplete from '@/components/common/EAutoComplete'

export default {
  name: 'EAutoCompleteVessel',
  components: {
    EAutoComplete
  },
  props: {
    ctrCd: { type: String, default: '', required: false },
    cdNm: { type: String, default: '', required: false },
    placeholder: { type: String, default: '', required: false }
  },
  data: function () {
    return {
      text: '',
      rtnVo: {},
      items: [],
      dynamicItems: []
    }
  },
  watch: {
    cdNm () {
      if (this.text !== this.$props.cdNm) {
        this.text = this.$props.cdNm
      }
    }
  },
  created () {
    if (this.text !== this.$props.cdNm) {
      this.text = this.$props.cdNm
    }
  },
  methods: {
    dynamicSearchPlace: function (e) {
      const THIS = this
      const keyword = e

      if (keyword === '' || keyword.length < 2) {
        this.items = []
        this.dynamicItems = []
        return
      }

      apiVslScheduleInfo.getVesselInfo(keyword, this.ctrCd).then((data) => {
        if (data.headers.respcode === 'C0000') {
          this.items = data.data
          if (this.items === undefined) {
            THIS.dynamicItems = []
          } else {
            THIS.dynamicItems = this.items.map(vo => vo.cdNm)
          }
        }
      })
    },
    inputEvt (val) {
      if (val !== '') {
        val = val.toUpperCase()
      }

      if (this.items === undefined || this.items === null || this.items.length === 0) {
        this.rtnVo = {
          cd: '',
          cdNm: val
        }
        this.$emit('change', this.rtnVo)
        return
      }

      const tvo = this.items.find(vo => vo.cdNm === val)

      if (tvo !== undefined) {
        this.rtnVo = tvo
      } else {
        this.rtnVo = {
          cd: '',
          cdNm: val
        }
      }

      this.$emit('change', this.rtnVo)
    }
  }
}
</script>
