var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("e-auto-complete", {
    staticClass: "auto-input",
    attrs: { values: _vm.dynamicItems, placeholder: _vm.placeholder },
    on: { update: _vm.dynamicSearchPlace, input: _vm.inputEvt },
    model: {
      value: _vm.text,
      callback: function ($$v) {
        _vm.text = $$v
      },
      expression: "text",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }